<template>
  <div class="container">
    <FloatingButton icon="fas fa-home" @click="goBack"/>
    <LoadingFullPage v-if="isLoading"/>
    <div class="row" v-else>
        <Title text="Disputas" />
        <div class="col s12" v-for="dispute in disputes" :key="dispute._id">
            <SingleDispute :dispute="dispute"/>
        </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Admin/Title'
import FloatingButton from '@/components/UI/Buttons/FloatingButton'
import LoadingFullPage from '@/components/UI/LoadingFullPage'
import SingleDispute from '@/components/Dispute/SingleDispute'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
    components: { Title, FloatingButton, LoadingFullPage, SingleDispute },
    setup(){
        
        const { router, isLoading } = useBoilerplate(true);

        const disputes = ref([]);

        const goBack = () => router.push({name: "AdminPanel"});
        
        onMounted(async () => {
            try {
                const url = '/api/allDisputes';
                const res = await fetch(url, {cache: "no-cache"});
                const data = await res.json();
                disputes.value = data;
                isLoading.value = false;
            } catch (err) {
                console.log(err);
                isLoading.value = false;
            }
        })
        return { goBack, isLoading, disputes }
    }
}
</script>

<style>

</style>